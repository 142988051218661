import {
  submitTypeBusFlow, dataEditTypeBus, deleteTypeBus, comissionUseCase
} from '@/domain/usecase'
import {
  ref, getCurrentInstance, reactive, onMounted
} from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'

export default {
  props: {
    id: {
      default: () => ''
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit } :any) {
    const app = getCurrentInstance()
    const title = ref('Tambah Bagasi')
    const isActive = ref(false)
    const submitted = ref(false)
    const ket = ref('')
    const id = ref(props.id)
    const store = useStore()
    const dataForm = reactive({
      name: '',
      code: '',
      comissionId: ''
    })
    const comissions = ref([])

    const rules = {
      code: {
        required: helpers.withMessage('Kode Bagasi harus diisi', required)
      },
      name: {
        required: helpers.withMessage('Nama Bagasi harus diisi', required)
      },
      comissionId: {
        required: helpers.withMessage('Komisi harus diisi', required)
      },
    }
    const v$ = useVuelidate(rules, dataForm)

    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties

    const getDataEdit = async (idTipe: any) => {
      const response = await dataEditTypeBus(idTipe)
      console.log('response', response)
      if (!response.error) {
        dataForm.code = response.result.Kode
        dataForm.name = response.result.Nama
        dataForm.comissionId = response.result.KomisiHdId
        isActive.value = response.result.IsActive
      }
    }

    if (id.value) {
      title.value = 'Edit Bagasi'
      getDataEdit(id.value)
    }

    const getDataComission = async () => {
      const response = await comissionUseCase.getAll({
        filter: 'IsActive eq true'
      })
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        comissions.value = response.result.map((x: any) => ({
          value: x.Id,
          label: x.Nama
        }))
      }
      console.log('response', response)
    }

    const checkErorResponse = (response: any) => {
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: $strInd.toast.successSubmit,
          group: 'bc',
          life: 1500
        })
        emit('reloadData')
      } else {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error', message: response.error, duration: 1500
        })
      }
    }

    const submitProccess = async () => {
      store.dispatch('showLoading')
      const data = {
        Id: id.value,
        Kode: dataForm.code.toUpperCase(),
        Nama: dataForm.name,
        IsActive: isActive.value,
        KomisiHdId: dataForm.comissionId
      }
      const response = await submitTypeBusFlow(data)
      checkErorResponse(response)
    }

    const submitData = (isFormValid: boolean) => {
      // console.log(isFormValid)
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    const deleteData = (e:any) => {
      $confirm.require({
        header: 'Bagasi',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await deleteTypeBus(id.value)
          checkErorResponse(response)
        }
      })
    }

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    onMounted(() => {
      getDataComission()
    })

    return {
      isActive,
      title,
      id,
      ket,
      comissions,
      submitted,
      deleteData,
      submitData,
      dataForm,
      v$,
      closeSidebar
    }
  }
}
